import api_endpoints from '@services/api_endpoints'
import { getRequest, postRequest } from '@services/axios'

import { setPrintZplData } from '@store/Slices/persistedDataSlice'
import { setBarcodeInfo, setProductivityCount } from '@store/Slices/pricing'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
export default function useClothingEnhancer() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loginInfo } = useSelector((store: any) => store.persistedData)
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)
  const { selectedColour } = useSelector((store: any) => store.pricing)
  const { processPalletInfo } = useSelector((store: any) => store.pricing)

  async function getBarcodeData(
    vendorId: number,
    qty: number,
    s2cId: any,
    scannerId: string,
    userName: string,
    personId: number,
    palletId: number,
    dashboardType: string,
    categoryId: string,
    barcodeTitle: string,
    barcodeDesc: string
  ) {
    if (!palletId) {
      dispatch(
        setNotification({
          notification: t('Please enter pallet ID'),
          variant: 'warning',
        })
      )
      return
    }
    dispatch(setLoader(true))
    const inputParam: any = {
      vendor_id: vendorId,
      qty,
      s2c_id: parseInt(s2cId) ?? 0,
      scanner_id: 'Pricing_Web',
      user_name: userName,
      person_id: personId,
      pallet_id: palletId,
      dashboard_type: dashboardType,
      category_id: categoryId,
      badge_number: loginInfo?.badgeID,
    }
    const barcodeDetailsResponse: any = await postRequest(
      api_endpoints.getBarcodeDetails,
      {
        inputParams: inputParam,
      },
      {
        headers: {
          transaction_type: 'pricing_app',
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        },
      }
    )
    if (barcodeDetailsResponse?.success) {
      const barcodeArray = barcodeDetailsResponse?.data?.barcode_id
      let barcodeData = ''
      const barcodeDescAPI =
        '' + barcodeDetailsResponse?.data?.description ?? ''
      const priceNumber = barcodeDetailsResponse?.data?.price ?? 0.0
      const price = Number(priceNumber).toFixed(2) + ''
      const upc = barcodeDetailsResponse?.data?.upc + ''
      let mainCategory = '' + (selectedSubCategory ?? '')
      if (mainCategory.includes(')')) {
        mainCategory = mainCategory.split(')')[1]
      }
      const stockNumber =
        '' +
        ((processPalletInfo?.launchId == null
          ? processPalletInfo?.stockNumber
          : processPalletInfo?.launchId) ?? '')
      dispatch(setBarcodeInfo(barcodeDetailsResponse?.data))
      dispatch(setLoader(false))

      for (const id of barcodeArray) {
        //   const bCode = 'yourBCodeHere'
        const barcodeID = '' + id ?? 0.0
        const barcodePadded = barcodeID.padStart(10, '0')
        const barcodeIDconverted =
          barcodePadded.slice(0, 3) +
          '-' +
          barcodePadded.slice(3, 6) +
          '-' +
          barcodePadded.slice(6, 10)
        if (
          dashboardType === 'template_acc' ||
          dashboardType === 'template_dom'
        ) {
           !selectedColour ? barcodeData = barcodeData + `^XA
              ^XFE:CCMLB22.ZPL^FS
              ^FN12^FD${stockNumber}^FS
              ^FN13^FD${barcodeID}$${price}!#&U:${upc}&D:${barcodeDescAPI}^FS
              ^FN14^FD${mainCategory}^FS
              ^FN15^FD${barcodeIDconverted}^FS
              ^PQ1 
              ^XZ`
            : barcodeData =
            barcodeData +

            `^XA
^CE00
^CE28
^FS~JS
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS
^FS
^FT187,45
^A0N,23,21
^FH\
^FDCOMP EST VALUE
^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&U:${upc}&D:${barcodeDescAPI}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT60,196
^A0N,23,24
^FH\
^FN14^FD${selectedColour}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategory}^FS
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11
^FS
^XZ`
      }
        if (dashboardType === 'footwear') {
           selectedColour ? barcodeData = barcodeData + `^XA
^CE00
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS 
^FS
^FT187,45
^A0N,23,21
^FH\
^FD ${selectedColour} ^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&U:${upc}&D:${barcodeDesc}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategory}^FS 
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11^FD$${price}^FS 
^FS
^XZ` : barcodeData = barcodeData + `^XA
^CE00
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS 
^FS
^FT187,45
^A0N,23,21
^FH\
^FD
^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&U:${upc}&D:${barcodeDesc}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategory}^FS 
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11^FD$${price}^FS 
^FS
^XZ`
    }
      }
      console.log('BARCODE DATA', barcodeData)
      dispatch(
        setPrintZplData({
          printZplString: true,
          printValues: barcodeData,
          showNotification: true,
        })
      )
    } else {
      dispatch(setLoader(false))
      dispatch(
        setNotification({
          notification: 'Somethig went wrong please try again.',
          variant: 'error',
        })
      )
    }
  }

  async function updateProductivityCount(
    barcodeCount: number,
    badgeId?: number
  ) {
    const response: any = await getRequest(
      api_endpoints.updateProductivityCount(
        badgeId ?? loginInfo?.badgeID,
        barcodeCount
      ),
      {
        headers: {
          transaction_type: 'pricing_app',
          Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
        },
      }
    )
    console.log(
      'updateProductivityCount Response ',
      response?.data?.first_scan_time
    )

    dispatch(setProductivityCount(response?.data))
  }
  return { getBarcodeData, updateProductivityCount }
}
