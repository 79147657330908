import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import CustomButton from '@components/CustomButton'
import CustomDropdown from '@components/Dropdown'
import CustomInput from '@components/Input'
import { useEffect, useState } from 'react'
import usePalletEnhancer from './usePalletEnhancer'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '@store/Slices/utilitySlice'
import {
  removePalletData,
  removeUPCData,
  removeBarcodeInfo,
  removeCateory,
  setSelectedTeamNumber,
  setPalletInfo,
  removeLetterClothingUPCInfo,
  removeProcessPalletInfo,
  setProcessPalletInfo,
  setSelectedColour,
  removeSelectedColour,
} from '@store/Slices/pricing'
import Loader from '@components/Loader'
import { InputGroup } from 'rsuite'
import InputScannerImg from '@img/input_scanner_dark.svg'
import Checkbox from '@img/checkbox.svg'
import CheckboxChecked from '@img/checkbox-checked.svg'
import {
  removeMainCategory,
  setMainCategory,
  setSubCategory,
  removeSubCategory,
} from '@store/Slices/dashboard'
import { NumberSort } from '@helpers/index'

export default function PalletScan({
  selectedVendor,
  personData,
  t,
  isRawPalletScanMode,
  setRawPalletMode,
  scanTemplateData,
  setScanTemplateData,
}: any) {
  const data = [].map((item) => ({ label: item, value: item }))
  const [orderedTeamNumbers, setOrderedTeamNumbers] = useState(data)
  const { teamNumbers, selectedMainCategory, selectedSubCategory } =
    useSelector((store: any) => store.dashboard)

  const { selectedTeamNumber } = useSelector((store: any) => store.pricing)
  const { selectedColour } = useSelector((store: any) => store.pricing)

  useEffect(() => {
    if (teamNumbers) {
      setOrderedTeamNumbers(
        NumberSort(teamNumbers).map((item: number) => ({
          label: item,
          value: item,
        }))
      )
    }
  }, [teamNumbers])

  // const categories = categoryList?.map((item: string) => ({
  //   label: item,
  //   value: item,
  // }))
  const dispatch = useDispatch()
  const { palletInfo, processPalletInfo } = useSelector(
    (store: any) => store.pricing
  )
  // const { printZplString } = useSelector((store: any) => store.persistedData)
  const {
    getPalletDetails,
    scanTemplate,
    addLooseItems,
    printToteLabel,
    // getCategoryList,
  } = usePalletEnhancer({
    scanTemplateData,
    setScanTemplateData,
  })

  const [palletID, setPalletID] = useState<string>('')
  const [processPalletID, setProcessedPalletID] = useState<string>('')
  const [scanTemplatePalletID, setScanTemplatePalletID] = useState<string>('')
  const [isTeamNumberErr, setTeamNumberErr] = useState<boolean>(false)

  const loader = useSelector((store: any) => store.utility.loader)

  const palletScannerStyle = {
    width: '262px',
  }

  useEffect(() => {
    if (palletInfo.stockName) {
      setPalletID(palletInfo?.RawPalletId)
      setProcessedPalletID(processPalletInfo?.RawPalletId)
      dispatch(setMainCategory(processPalletInfo?.mainCategory ?? ''))
      dispatch(setSubCategory(processPalletInfo?.subCategory ?? ''))
      dispatch(setSelectedColour(processPalletInfo?.colourName ?? ''))
    }
  }, [palletInfo])
  useEffect(() => {
    dispatch(removeSelectedColour())
    dispatch(setMainCategory(processPalletInfo?.mainCategory ?? ''))
    dispatch(setSubCategory(processPalletInfo?.subCategory ?? ''))
    dispatch(setSelectedColour(processPalletInfo?.colourName ?? ''))
    if (
      processPalletInfo?.subCategory === null ||
      processPalletInfo?.subCategory === ''
    ) {
      dispatch(removeSelectedColour())
      dispatch(
        setNotification({
          notification: t(
            'Subcategory not available, please select correct category while creating processed pallet ticket.'
          ),
          variant: 'warning',
        })
      )
    }
  }, [processPalletInfo])

  useEffect(() => {
    if (scanTemplateData?.acc?.status || scanTemplateData?.dom?.status) {
      setScanTemplatePalletID('') // wipe out the input field data if template pallet is found
    }
  }, [scanTemplateData?.acc, scanTemplateData?.dom])
  // useEffect(() => {
  //   if (selectedMainCategory === '') {
  //     getCategoryList()
  //   }
  // }, [])

  return (
    <div className="pallet-container">
      {loader && <Loader />}
      <div className="pallet-wrapper">
        <div className="main-area">
          <div className="pallet-breadcrumb">
            <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Pallet Scan']} />

            {/* TEAM NUMBER SECTION */}
            <div className="team-number-row">
              <label className="team-label team-number-label">
                Team Number
              </label>
              <CustomDropdown
                data={orderedTeamNumbers}
                style={{ width: 262 }}
                searchable={false}
                menuMaxHeight={200}
                cleanable={false}
                value={selectedTeamNumber}
                placeholder={'Select team no.'}
                onChange={(value: any) => {
                  dispatch(setSelectedTeamNumber(value))
                  setTeamNumberErr(false)
                }}
                isError={isTeamNumberErr}
              />
            </div>

            <hr />

            {/* SCAN PALLET AND STOCK NUMBER SECTION */}
            <div className="team-number-row stock-name">
              <label className="team-label">Stock Name</label>
              <CustomInput
                disabled
                value={isRawPalletScanMode ? palletInfo?.stockName ?? '' : ''}
                textColor={'#000'}
                width={'670px'}
              />
            </div>

            <div className="category-pallet-row">
              <div className="team-number-row">
                <label className="team-label">
                  {isRawPalletScanMode ? 'Scan Raw Pallet' : 'Scan Template'}
                </label>
                <InputGroup inside style={palletScannerStyle}>
                  <CustomInput
                    id="palletInput"
                    onChange={(value: any) => {
                      isRawPalletScanMode
                        ? setPalletID(value)
                        : setScanTemplatePalletID(value)
                    }}
                    onKeyPress={(event: any) => {
                      if (event.key === '~') {
                        event.preventDefault()
                      }
                    }}
                    onPaste={(event: any) => {
                      const pastedValue = event.clipboardData.getData('text')

                      if (pastedValue.includes('~')) {
                        dispatch(
                          setNotification({
                            notification: t('Only numeric values are allowed'),
                            variant: 'error',
                          })
                        )
                        event.preventDefault()
                      }
                    }}
                    style={{ border: '3px solid #000000' }}
                    // type="number"
                    value={isRawPalletScanMode ? palletID : scanTemplatePalletID}
                    onFocus={() => {
                      if (palletInfo?.stockName && isRawPalletScanMode) {
                        dispatch(removePalletData())
                        dispatch(removeUPCData())
                        dispatch(removeLetterClothingUPCInfo())
                        dispatch(removeBarcodeInfo())
                        dispatch(removeCateory())
                        setPalletID('')
                        dispatch(removeSelectedColour())
                      }
                    }}
                    onKeyDown={(event: any) => {
                      if (loader) {
                        return
                      }
                      if (event.keyCode === 13 || event.key === 'Enter') {
                        if (!isRawPalletScanMode) {
                          if (!selectedTeamNumber) {
                            dispatch(
                              setNotification({
                                notification: t('Please select team number'),
                                variant: 'warning',
                              })
                            )
                            setTeamNumberErr(true)
                            return
                          } else if (
                            // On scan template for dom and acc
                            scanTemplateData?.dom?.status &&
                            scanTemplateData?.acc?.status
                          ) {
                            dispatch(
                              setNotification({
                                notification: t(
                                  'Template Pallets are already scanned.'
                                ),
                                variant: 'warning',
                              })
                            )
                            return
                          }
                          scanTemplate(event.target.value)
                        } else if (!selectedTeamNumber) {
                          dispatch(
                            setNotification({
                              notification: t('Please select team number'),
                              variant: 'warning',
                            })
                          )
                          setTeamNumberErr(true)
                        } else {
                          const inputElement =
                            document.getElementById('palletInput')
                          if (inputElement) {
                            inputElement.blur()
                          }
                          const scannedValue = '' + event.target.value
                          if (scannedValue.includes('&P:')) {
                            const splitP = scannedValue.split('&P:')
                            if (!splitP[1]) {
                              dispatch(
                                setNotification({
                                  notification: t('Invalid Pallet'),
                                  variant: 'warning',
                                })
                              )
                              return
                            }

                            const splitS = splitP[1].split('&S:')
                            const splitSN = splitS[1].split('&SN:')
                            const RawPalletId = splitS[0]
                            const stockNumber = splitSN[0]
                            const stockName = splitSN[1]

                            const object: any = {
                              stockName,
                              RawPalletId: parseInt(RawPalletId),
                              stockNumber: parseInt(stockNumber),
                            }
                            dispatch(setPalletInfo(object))
                          } else {
                            getPalletDetails(
                              event.target.value,
                              personData?.personName + '.' + personData?.lastName,
                              selectedTeamNumber,
                              true
                            )
                          }
                        }
                      }
                    }}
                  />
                  <InputGroup.Addon>
                    <img src={InputScannerImg} className="input_icon" />
                  </InputGroup.Addon>
                </InputGroup>

                {/* <CustomButton
                  buttonText={
                    isRawPalletScanMode ? 'Scan Template' : 'Scan Raw Pallet'
                  }
                  className="pallet-scan-btn scan-template-btn"
                  onClick={() => {
                    isRawPalletScanMode && dispatch(removePalletData())
                    setRawPalletMode(!isRawPalletScanMode)
                  }}
                  isActive
                /> */}
              </div>
              {/* COLOUR SECTION */}
              <div className='team-number-row'>
                <label className="team-right-label">
                  Colour
                </label>
                <CustomInput
                  disabled
                  value={ processPalletID ? selectedColour : ''}
                  textColor={'#000'}
              />
              </div>
            </div>
            {isRawPalletScanMode && (
              <div className="category-pallet-row">
                <div className="team-number-row">
                  <label className="team-label team-number-label">
                    Category
                  </label>

                  <CustomInput
                    disabled
                    value={selectedSubCategory}
                    textColor={'#000'}
                    width={'240px'}
                  />
                  {/* <CustomDropdown
                    data={[]}
                    style={{ width: 262 }}
                    searchable={false}
                    menuMaxHeight={200}
                    cleanable={false}
                    value={selectedMainCategory}
                    placeholder={'Please select'}
                    onChange={(value: any) => {
                      dispatch(setMainCategory(value))
                    }}
                  /> */}
                </div>
                <div className="team-number-row">
                  <label className="team-right-label process-pallet-label">
                    Scan Processed Pallet
                  </label>
                  <InputGroup inside style={palletScannerStyle}>
                    <CustomInput
                      id="palletInput"
                      onChange={(value: any) => {
                        setProcessedPalletID(value)
                      }}
                      onKeyPress={(event: any) => {
                        if (event.key === '~') {
                          event.preventDefault()
                        }
                      }}
                      onPaste={(event: any) => {
                        const pastedValue = event.clipboardData.getData('text')

                        if (pastedValue.includes('~')) {
                          dispatch(
                            setNotification({
                              notification: t(
                                'Only numeric values are allowed'
                              ),
                              variant: 'error',
                            })
                          )
                          event.preventDefault()
                        }
                      }}
                      style={{ border: '3px solid #000000' }}
                      value={processPalletID}
                      onFocus={() => {
                        if (palletInfo?.stockName && isRawPalletScanMode) {
                          dispatch(removeProcessPalletInfo())
                          dispatch(removeMainCategory())
                          dispatch(removeSubCategory())
                          // dispatch(removeUPCData())
                          // dispatch(removeLetterClothingUPCInfo())
                          // dispatch(removeBarcodeInfo())
                          // dispatch(removeCateory())
                          setProcessedPalletID('')
                        }
                      }}
                      onKeyDown={(event: any) => {
                        if (loader) {
                          return
                        }
                        if (event.keyCode === 13 || event.key === 'Enter') {
                          if (!isRawPalletScanMode) {
                            if (!selectedTeamNumber) {
                              dispatch(
                                setNotification({
                                  notification: t('Please select team number'),
                                  variant: 'warning',
                                })
                              )
                              setTeamNumberErr(true)
                              return
                            } else if (
                              // On scan template for dom and acc
                              scanTemplateData?.dom?.status &&
                              scanTemplateData?.acc?.status
                            ) {
                              dispatch(
                                setNotification({
                                  notification: t(
                                    'Template Pallets are already scanned.'
                                  ),
                                  variant: 'warning',
                                })
                              )
                              return
                            }
                            scanTemplate(event.target.value)
                          } else if (!selectedTeamNumber) {
                            dispatch(
                              setNotification({
                                notification: t('Please select team number'),
                                variant: 'warning',
                              })
                            )
                            setTeamNumberErr(true)
                          } else {
                            const inputElement =
                              document.getElementById('palletInput')
                            if (inputElement) {
                              inputElement.blur()
                            }
                            const scannedValue = '' + event.target.value
                            if (scannedValue.includes('&P:')) {
                              const splitP = scannedValue.split('&P:')
                              if (!splitP[1]) {
                                dispatch(
                                  setNotification({
                                    notification: t('Invalid Pallet'),
                                    variant: 'warning',
                                  })
                                )
                                return
                              }

                              const splitS = splitP[1].split('&S:')
                              const splitSN = splitS[1].split('&SN:')
                              const RawPalletId = splitS[0]
                              const stockNumber = splitSN[0]
                              const stockName = splitSN[1]

                              const object: any = {
                                stockName,
                                RawPalletId: parseInt(RawPalletId),
                                stockNumber: parseInt(stockNumber),
                              }
                              dispatch(setProcessPalletInfo(object))
                            } else {
                              if (!palletID) {
                                dispatch(
                                  setNotification({
                                    notification: t(
                                      'Please scan raw pallet first'
                                    ),
                                    variant: 'warning',
                                  })
                                )
                              }
                              // else if (!selectedMainCategory) {
                              //   dispatch(
                              //     setNotification({
                              //       notification: t('Please select category'),
                              //       variant: 'warning',
                              //     })
                              //   )
                              // }
                              else {
                                getPalletDetails(
                                  event.target.value,
                                  personData?.personName +
                                    '.' +
                                    personData?.lastName,
                                  selectedTeamNumber,
                                  false
                                )
                              }
                            }
                          }
                        }
                      }}
                    />
                    <InputGroup.Addon>
                      <img src={InputScannerImg} className="input_icon" />
                    </InputGroup.Addon>
                  </InputGroup>
                </div>
              </div>
            )}
            <div className="scan-template-section">
              <div
                className="checkbox"
                onClick={() => {
                  setRawPalletMode(!isRawPalletScanMode)
                }}
              >
                {isRawPalletScanMode ? (
                  <img src={Checkbox} alt="checkbox" />
                ) : (
                  <img src={CheckboxChecked} alt="checked-checkbox" />
                )}
              </div>
              <div className="scan-temp-text">Scan Template</div>
            </div>

            {/* ALL BUTTONS SECTION */}
            <div className="all-btn-wrapper">
              <div className="pallet-scan-buttons">
                <CustomButton
                  buttonText={'Add Template Domestics'}
                  className="pallet-scan-btn"
                  isActive={scanTemplateData?.dom?.status}
                  onClick={() => {
                    addLooseItems(scanTemplateData?.dom)
                  }}
                />
                <CustomButton
                  buttonText={'Add Template Accessories'}
                  className="pallet-scan-btn"
                  isActive={scanTemplateData?.acc?.status}
                  onClick={() => {
                    addLooseItems(scanTemplateData?.acc)
                  }}
                />
                <CustomButton
                  buttonText={'Close'}
                  className="pallet-scan-btn"
                  isActive={
                    scanTemplateData?.acc?.status ||
                    scanTemplateData?.dom?.status
                  }
                  onClick={() =>
                    setScanTemplateData({
                      acc: { status: false, palletId: '', desc: '' },
                      dom: { status: false, palletId: '', desc: '' },
                    })
                  }
                />
              </div>
              <div className="tote-label-btn">
                <CustomButton
                  buttonText={'Print Tote Label'}
                  className="pallet-scan-btn"
                  isActive={(palletInfo?.stockName ?? '') !== ''}
                  onClick={() => {
                    printToteLabel(
                      palletInfo?.RawPalletId,
                      palletInfo?.stockNumber,
                      palletInfo?.stockName ?? ''
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {palletInfo?.RawPalletId && !isRawPalletScanMode && (
          <hr className="clothing-hr" style={{ marginLeft: '50px' }} />
        )}

        {palletInfo?.RawPalletId && !isRawPalletScanMode && (
          <div className="info-box-category-Pallet">
            <div className="input-container-Pallet">
              <label>Raw Pallet ID</label>
              <CustomInput
                width={'240px'}
                style={{ border: '3px solid #000000' }}
                textColor={'#000'}
                value={palletInfo?.RawPalletId}
                disabled
              />
            </div>

            <div className="input-container-Pallet">
              <label>Category</label>
              <CustomInput
                width={'240px'}
                style={{ border: '3px solid #000000' }}
                textColor={'#000'}
                value={selectedMainCategory}
                disabled
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
