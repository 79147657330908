import { getRequestIdentifier } from '@helpers/index'
import api_endpoints from '@services/api_endpoints'
import { getRequest } from '@services/axios'
import {
  removeBarcodeInfo,
  removeCateory,
  removeLetterClothingUPCInfo,
  resetLooseItemsCount,
} from '@store/Slices/pricing'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function usePricingEnhancer() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('Pallet Scan')
  const [pricingData, setPricingData] = useState<any>({})
  const [selectedClothingCat, setSelectedClothingCat] = useState<any>({})
  const [selectedFootwearCat, setSelectedFootwearCat] = useState<any>({})
  const [selectedTemplateAccCat, setSelectedTemplateAccCat] = useState<any>({})
  const [selectedDOMParentCard, setSelectedDOMParentCard] = useState<any>(4559)
  const [selectedDOMChildCard, setSelectedDOMChildCard] = useState<any>({})
  const [selectedRugsCat, setSelectedRugsCat] = useState<any>({})
  const [selectedMiscCat, setSelectedMiscCat] = useState<any>({})
  const [selectedLetterClothingCat, setSelectedLetterClothingCat] =
    useState<any>({})

  const [showPopup, setShowPopup] = useState(false)
  const [showNoUPCPopup, setShowNoUPCPopup] = useState(false)
  const [isRawPalletScanMode, setRawPalletMode] = useState(true)
  const [scanTemplateData, setScanTemplateData] = useState({
    acc: { status: false, palletId: '', desc: '' },
    dom: { status: false, palletId: '', desc: '' },
  })
  const [otherAmoutPrice, setOtherAmoutPrice] = useState('')
  const [otherAmoutSelected, setotherAmoutSelected] = useState(false)
  const [showPriceEditPopup, setshowPriceEditPopup] = useState(false)

  const { selectedVendor, selectedMainCategory } = useSelector(
    (store: any) => store.dashboard
  )
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo, processPalletInfo, barcodeInfo, LetterClothingUPCInfo } =
    useSelector((store: any) => store.pricing)

  const { selectedTeamNumber } = useSelector((store: any) => store.pricing)
  const [barcodeTitle, setBarcodeTitle] = useState('')
  const [barcodeDesc, setBarcodeDesc] = useState('')
  const [tabs, setTabs] = useState([
    { tab: 'Pallet Scan', hide: false, clickable: true },
    { tab: 'Item/UPC Scan', hide: false, clickable: true },
    // { tab: 'Clothing', hide: false, clickable: true },
    { tab: 'Template DOM', hide: true, clickable: true },
    { tab: 'Template ACC', hide: true, clickable: true },
    { tab: 'Footwear', hide: false, clickable: true },
    { tab: 'Rugs', hide: true, clickable: true },
    { tab: 'Misc', hide: true, clickable: true },
  ])

  const letterClothingVendorIDs = [113, 5, 190, 38, 166, 159, 106] // [113, 5, 190, 38]

  const isLetterClothing = letterClothingVendorIDs.includes(selectedVendor?.ID)

  useEffect(() => {
    //  stock name contains amazon then only we are showing the templateDOM and template acc, Misc also visible in flag which should came from the backend
    if (
      palletInfo.stockName &&
      selectedMainCategory !== '' &&
      processPalletInfo?.stockNumber
    ) {
      setTabs([
        { tab: 'Pallet Scan', hide: false, clickable: true },
        { tab: 'Item/UPC Scan', hide: false, clickable: true },
        // { tab: 'Clothing', hide: false, clickable: true },
        {
          tab: 'Template DOM',
          hide: !palletInfo.stockName.toLowerCase().includes('amazon'),
          clickable: true,
        },
        {
          tab: 'Template ACC',
          hide: !palletInfo.stockName.toLowerCase().includes('amazon'),
          clickable: true,
        },
        // { tab: 'Footwear', hide: false, clickable: true },
        { tab: 'Rugs', hide: false, clickable: true },
        {
          tab: 'Misc',
          hide: !selectedVendor?.VENDORNAME.includes('ALL OTHER VENDORS'),
          clickable: true,
        },
      ])
    } else {
      setTabs([
        { tab: 'Pallet Scan', hide: false, clickable: true },
        { tab: 'Item/UPC Scan', hide: true, clickable: true },
        // { tab: 'Clothing', hide: true, clickable: true },
        { tab: 'Template DOM', hide: true, clickable: true },
        { tab: 'Template ACC', hide: true, clickable: true },
        //  { tab: 'Footwear', hide: true, clickable: true },
        { tab: 'Rugs', hide: true, clickable: true },
        { tab: 'Misc', hide: true, clickable: true },
      ])
    }
  }, [palletInfo, selectedMainCategory, processPalletInfo])

  const { loginInfo } = useSelector((store: any) => store.persistedData)

  useEffect(() => {
    if (!personData?.personId) {
      navigate('/')
    } else if (!selectedVendor?.ID) {
      navigate('/dashboard')
    }

    return () => {
      dispatch(resetLooseItemsCount()) // to reset loose domestic and loose accessories count items but leave the pricing page
    }
  }, [])

  useEffect(() => {
    dispatch(removeBarcodeInfo())
    dispatch(removeCateory())
    setSelectedClothingCat({})
    setSelectedFootwearCat({})
    if (Object.keys(LetterClothingUPCInfo).length) {
      dispatch(removeLetterClothingUPCInfo())
    }
    if (activeTab !== 'Pallet Scan' && activeTab !== 'Item/UPC Scan') {
      getPricingData(
        activeTab.split(' ').join('_').toUpperCase(),
        palletInfo?.stockName.replaceAll('&', '%26') || '',
        selectedTeamNumber,
        getLetterClothingEndPoint()
      )
    }
  }, [activeTab])
  function getLetterClothingEndPoint() {
    const stockNumber = processPalletInfo?.stockNumber || ''
    const vendorId = '' + selectedVendor?.ID ?? ''
    setSelectedLetterClothingCat({})
    return isLetterClothing
      ? `&stockNumber=${stockNumber}&vendorId=${vendorId}`
      : ''
  }
  const footwearOrder = [
    'WOMEN',
    'CHILDREN',
    'MEN',
    'FLIP FLOPS',
    'BOOTS',
    'SLIPPERS',
    'SANDALS',
    'WINTERBOOTS',
  ]

  const letterClothingOrder = [
    'ALL SEASON CLOTHING',
    'OUTERWEAR',
    'SWIMWEAR',
    'CHRISTMAS CLOTHING',
    'HALLOWEEN CLOTHING',
    'ST.PATRICK CLOTHING',
    'FORMALWEAR',
    'CHILD CLOTHING',
    'CHILD OUTERWEAR',
  ]

  function footwearItemSorting(a: any, b: any) {
    const aIndex = footwearOrder.indexOf(a.description[0].toUpperCase())
    const bIndex = footwearOrder.indexOf(b.description[0].toUpperCase())

    if (aIndex < bIndex) {
      return -1
    } else if (aIndex > bIndex) {
      return 1
    } else {
      return 0
    }
  }
  function getLetterClothingDesc(desc: any) {
    const value = desc?.split(')')
    const newDesc = [...value].pop()
    return newDesc
  }

  function letterClothingItemSorting(a: any, b: any) {
    const aIndex = letterClothingOrder.indexOf(
      getLetterClothingDesc(a.description).trim().toUpperCase()
    )
    const bIndex = letterClothingOrder.indexOf(
      getLetterClothingDesc(b.description).trim().toUpperCase()
    )

    if (aIndex < bIndex) {
      return -1
    } else if (aIndex > bIndex) {
      return 1
    } else {
      return 0
    }
  }

  async function getPricingData(
    type: string,
    stockName: string,
    teamNumber: number,
    letterClothing: string
  ) {
    dispatch(setLoader(true))
    console.log(
      'ENDPOINTS==================?',
      api_endpoints.getPricingData(type, stockName, teamNumber, '')
    )

    const pricingResponse: any = await getRequest(
      api_endpoints.getPricingData(type, stockName, teamNumber, letterClothing),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'Dashboard',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            palletInfo?.RawPalletId
          ),
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        },
      }
    )

    if (pricingResponse.success) {
      dispatch(setLoader(false))
      if (
        !Object.keys(pricingResponse?.data?.header).length ||
        !pricingResponse?.data?.header?.main_cat?.stock_name
      ) {
        setActiveTab('Pallet Scan')
        dispatch(
          setNotification({
            notification: t(
              `The selected vendor And the team number is not setup in the system to price ${type.toLowerCase()}`
            ),
            variant: 'error',
          })
        )
        return
      } else if (!pricingResponse?.data?.category.length) {
        setActiveTab('Pallet Scan')
        dispatch(
          setNotification({
            notification: t(
              `The selected vendor And the team number is not setup in the system to price ${type.toLowerCase()}`
            ),
            variant: 'error',
          })
        )
        return
      }
      setPricingData({
        ...pricingData,
        [type]:
          type === 'FOOTWEAR'
            ? {
                ...pricingResponse?.data,
                category:
                  pricingResponse?.data?.category?.sort(footwearItemSorting),
              }
            : type === 'CLOTHING' && isLetterClothing
            ? {
                ...pricingResponse?.data,
                category: pricingResponse?.data?.category?.sort(
                  letterClothingItemSorting
                ),
              }
            : pricingResponse?.data,
      })
    } else {
      dispatch(setLoader(false))
      setActiveTab('Pallet Scan')
      dispatch(
        setNotification({
          notification: t('Timeout reached, please retry after sometime'),
          variant: 'error',
        })
      )
    }
  }

  async function addDamageItem() {
    const barcodeID =
      (activeTab === 'Item/UPC Scan' || isLetterClothing) &&
      (typeof barcodeInfo?.barcode_id === 'string' ||
        typeof barcodeInfo?.barcode_id === 'number')
        ? barcodeInfo?.barcode_id
        : barcodeInfo?.barcode_id?.slice(-1)

    dispatch(setLoader(true))

    const damageItemResponse: any = await getRequest(
      api_endpoints.damageItem(barcodeID),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'damage-item',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            palletInfo?.RawPalletId
          ),
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        },
      }
    )
    if (damageItemResponse?.success) {
      dispatch(removeBarcodeInfo())
    }
    dispatch(setLoader(false))
    dispatch(
      setNotification({
        notification: damageItemResponse?.message,
        variant: damageItemResponse?.success ? 'success' : 'warning',
        translation: damageItemResponse?.translation ?? null,
      })
    )
  }

  return {
    setActiveTab,
    activeTab,
    tabs,
    pricingData,
    setTabs,
    selectedVendor,
    personData,
    selectedClothingCat,
    setSelectedClothingCat,
    selectedFootwearCat,
    setSelectedFootwearCat,
    showPopup,
    setShowPopup,
    barcodeDesc,
    setBarcodeDesc,
    barcodeTitle,
    setBarcodeTitle,
    selectedRugsCat,
    setSelectedRugsCat,
    selectedDOMParentCard,
    setSelectedDOMParentCard,
    selectedDOMChildCard,
    setSelectedDOMChildCard,
    selectedTemplateAccCat,
    setSelectedTemplateAccCat,
    selectedMiscCat,
    setSelectedMiscCat,
    showNoUPCPopup,
    setShowNoUPCPopup,
    isRawPalletScanMode,
    setRawPalletMode,
    scanTemplateData,
    setScanTemplateData,
    addDamageItem,
    otherAmoutPrice,
    setOtherAmoutPrice,
    otherAmoutSelected,
    setotherAmoutSelected,
    showPriceEditPopup,
    setshowPriceEditPopup,
    isLetterClothing,
    selectedLetterClothingCat,
    setSelectedLetterClothingCat,
  }
}
