import { Input, InputGroup, Tooltip, Whisper } from 'rsuite'
import './style.scss'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from '@img/search.svg'
import Close from '@img/close.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSelectedLang,
  setSelectedPrinter,
  setPrintZplData,
} from '@store/Slices/persistedDataSlice'
import ccmLogo from '@img/CCM-logo.svg'
import printer from '@img/printer.svg'
import bell from '@img/bell-icon.svg'
import maximize from '@img/maximize.svg'
import minimizeScreen from '@img/minimize-screen.svg'
import back from '@img/back.svg'
import { useNavigate } from 'react-router-dom'
import {
  removeLetterClothingUPCInfo,
  removePalletData,
  removeUPCData,
  removeSelectedTeamNumber,
  removeProcessPalletInfo,
  removeSelectedColour,
} from '@store/Slices/pricing'
import {
  deleteNotification,
  removeSelectedVendor,
  updateFullScreen,
  removeMainCategory,
  setPrinterStatus,
  setLastSelectedPrinter,
} from '@store/Slices/dashboard'
import { setNotification } from '@store/Slices/utilitySlice'
import ErrorIcon from '@img/icons/error.svg'
import WarningIcon from '@img/icons/warning.svg'
import SuccessIcon from '@img/icons/success-icon.svg'
import CustomDropdown from '@components/Dropdown'
import CustomButton from '@components/CustomButton'
import useLogoutEnhancer from '@helpers/useLogutEnhancer'

const searchInputGroupStyle = {
  border: 'none',
}

export default function Header({
  searchKeywords,
  setSearchKeyword,
  showSearchInput,
  t,
  showExit,
  showLogo,
  showPrinterLogo,
  showNotificationIcon,
  showFullScreenIcon,
}: any) {
  const [isSearchFoucesed, setIsSearchFoucesed] = useState(false)
  const { loginInfo, selectedLang } = useSelector(
    (store: any) => store.persistedData
  )
  const { logoutUser } = useLogoutEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = [].map((item) => ({
    label: item,
    value: item,
  }))

  const { lastSelectedPrinter } = useSelector((store: any) => store.dashboard)
  const [printerList, setPrinterList] = useState([])
  const [printListData, setPrintListData] = useState<any>(data)
  const whisperRef: any = useRef(null)

  const {
    isFullScreen,
    isPrinterAvailable,
    selectedSlideMenu,
    recentNotifications,
  } = useSelector((store: any) => store.dashboard)

  useEffect(() => {
    i18n.changeLanguage(selectedLang)
    const basicAuth = sessionStorage.getItem('Authorization')

    if (!basicAuth) {
      logoutUser()
    }
  }, [])
  useEffect(() => {
    updatePrinterListData()
  }, [printerList])
  useEffect(() => {
    if (lastSelectedPrinter === '') {
      setPrinterList([])
      setPrintListData([])
    }
  }, [lastSelectedPrinter])

  useEffect(() => {
    if (lastSelectedPrinter !== '') {
      getPrintersFromBrowserPrint()
    } else {
      getSelectedPrinterFromList(lastSelectedPrinter)
    }
  }, [])

  function updatePrinterListData() {
    if ((printerList || []).length !== 0) {
      const data = printerList.map(({ name, uid }) => ({
        label: name,
        value: uid,
      }))
      setPrintListData(data)
      getSelectedPrinterFromList(lastSelectedPrinter)
    }
  }

  function getSelectedPrinterFromList(value: string) {
    const selectedPrin = printerList?.filter((item: any) => item.uid === value)
    if (selectedPrin && selectedPrin?.length !== 0) {
      dispatch(
        setSelectedPrinter({
          selectedPrinter: selectedPrin[0],
        })
      )
      dispatch(setPrinterStatus({ isPrinterAvailable: true }))
      dispatch(
        setLastSelectedPrinter({
          lastSelectedPrinter: value,
        })
      )
    }
  }
  const getPrintersFromBrowserPrint = () => {
    const browserPrint = (window as any).BrowserPrint

    browserPrint.getLocalDevices(
      function (deviceList: any) {
        setPrinterList(deviceList.printer)
        updatePrinterListData()
        if (lastSelectedPrinter === '') {
          const selectedPrin = deviceList.printer?.filter(
            (item: any) => item.connection === 'usb'
          )

          if (selectedPrin && selectedPrin?.length !== 0) {
            dispatch(
              setSelectedPrinter({
                selectedPrinter: selectedPrin[0],
              })
            )
            dispatch(setPrinterStatus({ isPrinterAvailable: true }))
            dispatch(
              setLastSelectedPrinter({
                lastSelectedPrinter: selectedPrin[0].name,
              })
            )
          }
        }
      },
      (err: any) => {
        console.log(err)
      }
    )
  }
  const printButtonAction = () => {
    if (lastSelectedPrinter === '') {
      updatePrinterListData()
      dispatch(
        setNotification({
          notification: t('Printer is not connected'),
          variant: 'warning',
        })
      )

      dispatch(
        setPrinterStatus({
          isPrinterAvailable: false,
        })
      )
    } else {
      const networkCallResponse = '^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ'
      dispatch(
        setPrintZplData({
          printZplString: true,
          printValues: networkCallResponse,
          showNotification: true,
        })
      )

      dispatch(setPrinterStatus({ isPrinterAvailable: true }))
    }
  }
  const handleFullScreen = () => {
    if (document.documentElement.requestFullscreen && !isFullScreen) {
      dispatch(updateFullScreen())
      document?.documentElement?.requestFullscreen()
    } else {
      if (document?.exitFullscreen) {
        dispatch(updateFullScreen())
        document?.exitFullscreen()
      }
    }
  }

  const handlePrintButtonEvent = () => {
    getPrintersFromBrowserPrint()
    getSelectedPrinterFromList(lastSelectedPrinter)
  }

  const NotificationTooltip = (
    <Tooltip arrow={false} className="notification-tooltip">
      <div className="tooltip-container">
        {recentNotifications?.length ? (
          recentNotifications?.slice(0, 10).map((item: any, index: number) => (
            <>
              <div className="notification-box" key={index}>
                <div>
                  <img
                    src={
                      item.variant === 'warning'
                        ? WarningIcon
                        : item.variant === 'success'
                        ? SuccessIcon
                        : ErrorIcon
                    }
                    alt={'err-icon'}
                  />
                </div>
                <div className="notification-content">{item.notification}</div>
                <div
                  className="delete-notification"
                  onClick={() => dispatch(deleteNotification(item.id))}
                >
                  <img src={Close} alt={'err-icon'} />
                </div>
              </div>
              <hr />
            </>
          ))
        ) : (
          <p>{t('No Alerts')}</p>
        )}
      </div>
    </Tooltip>
  )
  const PrinterConfigTooltip = (
    <Tooltip arrow={false} className="printer-tooltip">
      <div className="printer-config-wrapper">
        <div className="printer-input-wrapper">
          <div className="printer-text">Select printer</div>
          <CustomDropdown
            searchable={false}
            data={printListData}
            menuMaxHeight={200}
            cleanable={false}
            onClick={handlePrintButtonEvent}
            onChange={(value: any) => {
              getSelectedPrinterFromList(value)
            }}
            value={lastSelectedPrinter}
          />
          <CustomButton
            buttonText="Test"
            isActive
            width="80px"
            onClick={printButtonAction}
          />
        </div>
      </div>
    </Tooltip>
  )

  let logoutTimeout: any // Initialize a timeout variable

  function forceLogoutUser() {
    dispatch(
      setNotification({
        notification: 'Logged out due to user inactivity.',
        variant: 'error',
      })
    )
    logoutUser()
  }

  // Function to reset the timeout
  function resetLogoutTimer() {
    logoutTimeout && clearTimeout(logoutTimeout)
    logoutTimeout = setTimeout(forceLogoutUser, 5 * 60 * 1000)
  }

  useEffect(() => {
    clearTimeout(logoutTimeout)
    document.addEventListener('mousemove', resetLogoutTimer)
    document.addEventListener('keydown', resetLogoutTimer)
    // Event listeners to detect user activity
    resetLogoutTimer()
    return () => {
      clearTimeout(logoutTimeout)
      document.removeEventListener('mousemove', resetLogoutTimer)
      document.removeEventListener('keydown', resetLogoutTimer)
    }
  }, [])

  useEffect(() => {
    // Add a beforeunload event listener

    const confirmLeave = (e: any) => {
      e.preventDefault() // This is necessary to display the confirmation dialog
      e.returnValue = '' // This is also necessary for some browsers
      // Display a confirmation dialog
      logoutUser()
    }
    window.addEventListener('beforeunload', confirmLeave)
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', confirmLeave)
    }
  }, [])

  return (
    <div className="header_wrapper">
      <div
        className={`left_menu ${showSearchInput ? '' : 'left-header-data'}`}
        style={{ width: showSearchInput ? '493px' : '55%' }}
      >
        {showSearchInput && selectedSlideMenu === 'dashboard' && (
          <InputGroup
            inside
            style={searchInputGroupStyle}
            className="search_group"
          >
            <InputGroup.Addon>
              <img src={Search} className="input_icon" />
            </InputGroup.Addon>
            <Input
              className="searchbox"
              onChange={setSearchKeyword}
              value={searchKeywords}
              placeholder={t('Search_vendor')}
              maxLength={30}
              onFocus={() => {
                setIsSearchFoucesed(true)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsSearchFoucesed(false)
                }, 100) // because on click we need to reset search keyword
              }}
            />
            {isSearchFoucesed && (
              <InputGroup.Addon>
                <img
                  src={Close}
                  className="input_icon"
                  onClick={() => setSearchKeyword('')}
                />
              </InputGroup.Addon>
            )}
          </InputGroup>
        )}
        {showLogo && (
          <div className="app-logo">
            <img src={ccmLogo} width={150} />
          </div>
        )}

        {showExit && (
          <div
            className="exit-btn"
            onClick={() => {
              navigate('/dashboard')
              dispatch(removePalletData())
              dispatch(removeProcessPalletInfo())
              dispatch(removeUPCData())
              dispatch(removeLetterClothingUPCInfo())
              dispatch(removeSelectedVendor())
              dispatch(removeMainCategory())
              dispatch(removeSelectedTeamNumber())
              dispatch(removeSelectedColour())
            }}
          >
            <>
              <img src={back} />
              Dashboard
            </>
          </div>
        )}
      </div>
      {showFullScreenIcon && (
        <div className="bell-icon" onClick={handleFullScreen}>
          <img
            src={isFullScreen ? minimizeScreen : maximize}
            alt={'full-screen'}
          />
        </div>
      )}

      {showNotificationIcon && (
        <Whisper
          placement="bottom"
          controlId="control-id-click"
          trigger="click"
          speaker={NotificationTooltip}
        >
          <div className="bell-icon">
            <img src={bell} alt={'bell-icon'} />
          </div>
        </Whisper>
      )}

      {showPrinterLogo && (
        <Whisper
          ref={whisperRef}
          placement="bottom"
          controlId="control-id-click"
          trigger="click"
          speaker={PrinterConfigTooltip}
          onExiting={() => {
            if (
              document.getElementsByClassName('rs-picker-select-menu').length
            ) {
              whisperRef.current.open()
            }
          }}
        >
          <div
            onClick={handlePrintButtonEvent}
            className={
              isPrinterAvailable ? 'printer-icon' : 'printer-icon-offline'
            }
          >
            <img src={printer} />
          </div>
        </Whisper>
      )}

      <div className="language_selection">
        <div
          className={selectedLang === 'es' ? 'first_lang' : 'second_lang'}
          onClick={() => {
            i18n.changeLanguage('en')
            dispatch(setSelectedLang('en'))
          }}
        >
          Eng
        </div>
        <div
          className={selectedLang === 'es' ? 'second_lang' : 'first_lang'}
          onClick={() => {
            i18n.changeLanguage('es')
            dispatch(setSelectedLang('es'))
          }}
        >
          Esp
        </div>
      </div>

      <div className="profile_section">
        <div className="avatar profile-content">
          <div>
            {personData?.personName?.charAt() + personData?.lastName?.charAt()}
          </div>
        </div>
        <div className="profile-content">
          <div className="profile_text_welcome">{t('welcome')}</div>
          <div className="profile_text_name">
            <span>
              {personData?.personName.charAt(0).toUpperCase() +
                personData?.personName.slice(1, 10).toLowerCase() +
                `${personData?.personName.length > 10 ? '...' : ' '}` +
                personData?.lastName?.charAt() +
                '.'}
            </span>
          </div>
          <div className="profile_text_warehouse">
            {loginInfo.selectedWarehouse}
          </div>
        </div>
      </div>
    </div>
  )
}
