import { checkIsInputInteger } from '@helpers/index'
import api_endpoints from '@services/api_endpoints'
import { getRequest } from '@services/axios'
import { upcGetRequest } from '@services/upcAxios'
import { setPrintZplData } from '@store/Slices/persistedDataSlice'
import {
  removeLetterClothingUPCInfo,
  removeUPCData,
  setBarcodeInfo,
  setLetterClothingUPCInfo,
  setUPCInfo,
} from '@store/Slices/pricing'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export default function useUPCScanEnhancer({ option }: any) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    palletInfo,
    processPalletInfo,
    selectedTeamNumber,
    upcInfo,
    selectedLetterClothingCategory,
  } = useSelector((store: any) => store.pricing)
  const { loginInfo } = useSelector((store: any) => store.persistedData)
  const { selectedColour } = useSelector((store: any) => store.pricing)
  const { personData } = useSelector((store: any) => store.personData)
  const { selectedVendor, selectedSubCategory, selectedMainCategory } =
    useSelector((store: any) => store.dashboard)
  const [enabledPriceEdit, setEnabledPriceEdit] = useState(false)
  const [removeUPCText, setRemoveUPCText] = useState(false)

  async function getStandardPriceForUPC(qty: number) {
    const endPoint = api_endpoints.getStandardPriceForUPC(
      selectedMainCategory,
      selectedSubCategory,
      selectedVendor?.ID,
      'Pricing_Web',
      personData?.personId,
      0,
      palletInfo?.RawPalletId,
      1,
      loginInfo?.badgeID
    )
    dispatch(setLoader(true))
    const upcResponse: any = await getRequest(endPoint, {
      headers: {
        transaction_type: 'pricing_app',
        Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        action_type: 'standard-price',
      },
    })
    if (upcResponse?.success && upcResponse?.data?.length) {
      console.log('RESPONSE', upcResponse)
      dispatch(setLoader(false))

      if (
        upcResponse?.data[0]?.price === null ||
        upcResponse?.data[0]?.price === ''
      ) {
        dispatch(
          setNotification({
            notification: upcResponse?.data[0]?.msg ?? '',
            variant: 'warning',
          })
        )
      } else {
        dispatch(setLoader(false))
        dispatch(setBarcodeInfo(upcResponse?.data[0]))
        const mainCategory = '' + (selectedSubCategory ?? '')
        const stockNumber =
          '' +
          ((processPalletInfo?.launchId == null
            ? processPalletInfo?.stockNumber
            : processPalletInfo?.launchId) ?? '')

        const priceNumber = upcResponse?.data[0]?.price ?? 0.0
        const price = Number(priceNumber).toFixed(2) + ''
        const upc = ''

        let barcodeIDZPL = ''
        const barcodeDesc = '' + upcResponse?.data[0]?.description ?? ''
        console.log('PRINT NO UPC BARCODE PAGE')
        const barcodeArray = upcResponse?.data[0]?.barcode_id
        const priceId = upcResponse?.data[0].price_source_id
        barcodeIDZPL = printMultipleBarcode(
          barcodeArray,
          barcodeIDZPL,
          price,
          stockNumber,
          upc,
          barcodeDesc,
          mainCategory,
          priceId
        )
        const notificationMessage =
          (upcResponse?.data[0]?.price ?? '') + '$ Standard Price Printed'
        console.log('BARCODE DATA', barcodeIDZPL)
        dispatch(
          setNotification({
            notification: notificationMessage,
            variant: 'success',
          })
        )
        dispatch(
          setPrintZplData({
            printZplString: true,
            printValues: barcodeIDZPL,
            showNotification: false,
          })
        )
      }
    }
  }
  async function updateUPCPrice(
    upcID: any,
    price?: string,
    desc?: string,
    qty?: number
  ) {
    setEnabledPriceEdit(false)
    if (!upcID) {
      dispatch(
        setNotification({
          notification: t('Please enter UPC ID'),
          variant: 'warning',
        })
      )
      return
    }
    if (upcID.includes('$') || upcID.includes('#') || upcID.includes('&')) {
      dispatch(
        setNotification({
          notification: t('Please enter valid UPC ID'),
          variant: 'warning',
        })
      )
      return
    }
    dispatch(setLoader(true))
    const endPoint = api_endpoints.getUPCDetailsForNoUPC(
      selectedVendor?.ID,
      '' + upcID,
      personData?.personName + '.' + personData?.lastName,
      'Pricing_Web',
      personData?.personId,
      0,
      palletInfo?.RawPalletId,
      parseFloat(price || '0.0'),
      encodeURIComponent(desc || ''),
      qty || 0,
      loginInfo?.badgeID
    )

    const upcResponse: any = await getRequest(endPoint, {
      headers: {
        transaction_type: 'pricing_app',
        Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        action_type: 'price-add',
      },
    })
    if (upcResponse?.success && upcResponse?.data?.length) {
      dispatch(setLoader(false))

      dispatch(
        setNotification({
          notification: upcResponse?.message || t('Price Updated'),
          variant: 'success',
          translation: upcResponse?.translation ?? null,
        })
      )

      dispatch(setBarcodeInfo(upcResponse?.data[0]))
      const mainCategory = '' + (selectedSubCategory ?? '')
      const stockNumber =
        '' +
        ((processPalletInfo?.launchId == null
          ? processPalletInfo?.stockNumber
          : processPalletInfo?.launchId) ?? '')

      const priceNumber = upcResponse?.data[0]?.price ?? 0.0
      const price = Number(priceNumber).toFixed(2) + ''
      const upc = '' + upcID
      let barcodeIDZPL = ''
      const barcodeDesc = '' + upcResponse?.data[0]?.description ?? ''
      const barcodeArray = upcResponse?.data[0]?.barcode_id
      const priceId = upcResponse?.data[0].price_source_id
      barcodeIDZPL = printMultipleBarcode(
        barcodeArray,
        barcodeIDZPL,
        price,
        stockNumber,
        upc,
        barcodeDesc,
        mainCategory,
        priceId
      )
      dispatch(
        setPrintZplData({
          printZplString: true,
          printValues: barcodeIDZPL,
          showNotification: true,
        })
      )
    } else {
      dispatch(setLoader(false))
      dispatch(
        setNotification({
          notification:
            upcResponse?.data?.length === 0
              ? upcResponse?.message
              : t('Timeout reached, please retry after sometime'),
          variant: 'error',
          translation:
            upcResponse?.data?.length === 0
              ? upcResponse?.translation ?? null
              : t('Timeout reached, please retry after sometime'),
        })
      )
    }
  }

  async function getItemDetails(
    upcID: number,
    price?: string,
    desc?: string,
    qty?: number,
    printNoUPC?: boolean,
    misc?: boolean
  ) {
    setRemoveUPCText(false)
    setEnabledPriceEdit(false)
    if (!printNoUPC && !upcID) {
      dispatch(
        setNotification({
          notification: t('Please enter UPC ID'),
          variant: 'warning',
        })
      )
      return
    }
    if (
      ('' + upcID).includes('$') ||
      ('' + upcID).includes('#') ||
      ('' + upcID).includes('&')
    ) {
      dispatch(
        setNotification({
          notification: t('Please enter valid UPC ID'),
          variant: 'warning',
        })
      )
      return
    }
    dispatch(setLoader(true))
    const endPoint = printNoUPC
      ? api_endpoints.getUPCDetailsForNoUPC(
          selectedVendor?.ID,
          '',
          personData?.personName + '.' + personData?.lastName,
          'Pricing_Web',
          personData?.personId,
          0,
          palletInfo?.RawPalletId,
          parseFloat(price || '0.0'),
          encodeURIComponent(desc || ''),
          qty || 0,
          loginInfo?.badgeID
        )
      : api_endpoints.getUPCDetails(
          selectedVendor?.ID,
          upcID,
          personData?.personName + '.' + personData?.lastName,
          'Pricing_Web',
          personData?.personId,
          0,
          palletInfo?.RawPalletId,
          processPalletInfo?.RawPalletId,
          loginInfo?.badgeID
        )

    const upcResponse: any = await upcGetRequest(endPoint, {
      headers: {
        transaction_type: 'pricing_app',
        Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        action_type: printNoUPC ? 'print-no-upc' : '',
      },
    })
    if (upcResponse?.success && upcResponse?.data?.length) {
      // Need to write the code for letter clothing
      dispatch(setLoader(false))
      printNoUPC && dispatch(setBarcodeInfo(upcResponse?.data[0]))
      const mainCategory = '' + (selectedSubCategory ?? '')
      const stockNumber =
        '' +
        ((processPalletInfo?.launchId == null
          ? processPalletInfo?.stockNumber
          : processPalletInfo?.launchId) ?? '')

      const priceNumber = upcResponse?.data[0]?.price ?? 0.0
      const price = Number(priceNumber).toFixed(2) + ''
      const upc = printNoUPC
        ? upcID === 0
          ? ''
          : upcID
        : upcID ?? upcResponse?.data[0]?.upc + ''
      let barcodeIDZPL = ''
      const barcodeDesc = '' + upcResponse?.data[0]?.description ?? ''
      if (printNoUPC) {
        console.log('PRINT NO UPC BARCODE PAGE')
        const barcodeArray = upcResponse?.data[0]?.barcode_id
        const priceId = upcResponse?.data[0].price_source_id
        barcodeIDZPL = printMultipleBarcode(
          barcodeArray,
          barcodeIDZPL,
          price,
          stockNumber,
          upc,
          barcodeDesc,
          mainCategory,
          priceId
        )
      } else {
        if (option === 'Clothing') {
          dispatch(
            setLetterClothingUPCInfo({ upc: upcID, ...upcResponse?.data[0] })
          )
        } else {
          dispatch(setUPCInfo({ upc: upcID, ...upcResponse?.data[0] }))
        }
        dispatch(setBarcodeInfo(upcResponse?.data[0]))
        const barcodeID = '' + upcResponse?.data[0]?.barcode_id ?? 0.0
        const barcodePadded = barcodeID.padStart(10, '0')
        const barcodeIDconverted =
          '' +
          barcodePadded.slice(0, 3) +
          '-' +
          barcodePadded.slice(3, 6) +
          '-' +
          barcodePadded.slice(6, 10)
        const priceId = upcResponse?.data[0].price_source_id
        barcodeIDZPL = getBarCodeZPL(
          price,
          stockNumber,
          barcodeID,
          upc,
          barcodeDesc,
          mainCategory,
          barcodeIDconverted,
          priceId
        )

        if (option === 'Clothing' || processPalletInfo.letter !== null) {
          console.log('palletInfo.letter', processPalletInfo.letter)
          console.log(
            'selectedLetterClothingCategory',
            selectedLetterClothingCategory
          )
          console.log('palletInfo.letter', processPalletInfo.letter !== null)

          if (
            option === 'Clothing' &&
            selectedLetterClothingCategory === undefined
          ) {
            return
          }

          let categoryKeyWords = ''
          const value = (
            selectedLetterClothingCategory.description ?? ''
          )?.split(')')
          const newDesc = '' + [...value].pop()

          if (option === 'Clothing') {
            // single character then add 2 space in right side
            categoryKeyWords =
              '' +
              (selectedLetterClothingCategory?.description ?? '')
                .split('(')
                .pop()
                .split(')')[0]
          } else {
            categoryKeyWords = processPalletInfo.letter
          }
          if (categoryKeyWords.length === 1) {
            categoryKeyWords = categoryKeyWords + '  '
          } else if (categoryKeyWords.length === 2) {
            categoryKeyWords = categoryKeyWords + ' '
          }
          let selectedSubCategoryForPrint = selectedSubCategory
          if (selectedSubCategoryForPrint.includes(')')) {
            selectedSubCategoryForPrint =
              selectedSubCategoryForPrint.split(')')[1]
            console.log(selectedSubCategoryForPrint.split(')')[0])
            console.log(selectedSubCategoryForPrint.split(')')[1])
          }
          const priceId = upcResponse?.data[0].price_source_id
          barcodeIDZPL = getBarcodeForLetter(
            barcodeIDZPL,
            barcodeID,
            price,
            upc,
            barcodeDesc,
            categoryKeyWords,
            stockNumber,
            barcodeIDconverted,
            newDesc,
            selectedSubCategoryForPrint,
            selectedColour,
            priceId
          )
        }
      }
      console.log('BARCODE DATA', barcodeIDZPL)
      dispatch(
        setPrintZplData({
          printZplString: true,
          printValues: barcodeIDZPL,
          showNotification: true,
        })
      )
      !printNoUPC &&
        dispatch(
          setNotification({
            notification: t('Item found'),
            variant: 'success',
          })
        )
    } else {
      setRemoveUPCText(true)
      dispatch(setLoader(false))
      dispatch(removeUPCData())
      if (option === 'Clothing') {
        dispatch(removeLetterClothingUPCInfo())
      } else {
        if (
          upcResponse?.message ===
            'Item not in database, see your supervisor for the price' &&
          checkIsInputInteger(upcID)
        ) {
          setEnabledPriceEdit(true)
        }
      }
      if (!upcResponse?.data?.length) {
        dispatch(
          setNotification({
            notification: upcResponse?.message,
            variant: 'warning',
            translation: upcResponse?.translation,
          })
        )
      }
    }
  }
  function printMultipleBarcode(
    barcodeArray: any,
    barcodeIDZPL: string,
    price: string,
    stockNumber: string,
    upc: string | number,
    barcodeDesc: string,
    mainCategory: string,
    priceId: string
  ) {
    for (const id of barcodeArray) {
      const barcodeID = '' + id ?? 0.0
      const barcodePadded = barcodeID.padStart(10, '0')
      const barcodeIDconverted =
        barcodePadded.slice(0, 3) +
        '-' +
        barcodePadded.slice(3, 6) +
        '-' +
        barcodePadded.slice(6, 10)
      barcodeIDZPL =
        barcodeIDZPL +
        getBarCodeZPL(
          price,
          stockNumber,
          barcodeID,
          upc,
          barcodeDesc,
          mainCategory,
          barcodeIDconverted,
          priceId
        )
    }
    return barcodeIDZPL
  }

  function getBarCodeZPL(
    price: string,
    stockNumber: string,
    barcodeID: string,
    upc: string | number,
    barcodeDesc: string,
    mainCategory: string,
    barcodeIDconverted: string,
    priceId: string
  ) {
    let mainCategoryPrint = '' + (mainCategory ?? '')
    if (mainCategoryPrint.includes(')')) {
      mainCategoryPrint = mainCategoryPrint.split(')')[1]
    }
    console.log('MAIN CATEGORY', mainCategoryPrint)

    let categoryKeyWords = '' + processPalletInfo.letter
    console.log('MAIN categoryKeyWords', categoryKeyWords)
    console.log('MAIN categoryKeyWords', categoryKeyWords.length)

    if (processPalletInfo.letter !== null) {
      if (categoryKeyWords.length === 1) {
        categoryKeyWords = categoryKeyWords + '  '
      } else if (categoryKeyWords.length === 2) {
        categoryKeyWords = categoryKeyWords + ' '
      }
      return getBarcodeForLetter(
        '',
        barcodeID,
        price,
        upc,
        barcodeDesc,
        categoryKeyWords,
        stockNumber,
        barcodeIDconverted,
        barcodeDesc,
        mainCategoryPrint,
        selectedColour,
        priceId
      )
    } else if (upc === '') {
      console.log('PRINTING PWUPC')
      return selectedColour ? `^XA
^CE00
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS 
^FS
^FT187,45
^A0N,23,21
^FH\
^FD ${selectedColour} ^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategoryPrint}^FS 
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11^FD$${price}^FS 
^FS
^XZ` : `^XA
^CE00
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS 
^FS
^FT187,45
^A0N,23,21
^FH\
^FD
^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategoryPrint}^FS 
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11^FD$${price}^FS 
^FS
^XZ` } else { return selectedColour ? `^XA
^CE00
^CE28
^FS~JS
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^MD8
^LRN
^CI0
^MMT
^PW406
^LL0243
^LS0
^FT186,162
^A0N,51,48
^FH\
^FN12^FD${stockNumber}^FS
^FS
^FT187,45
^A0N,23,21
^FH\
^FDCOMP EST VALUE
^FS
^BY5,0
^FT25,166
^BON,5,N,0,N,1,
^FH\
^FN13^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}^FS
^FS
^FT187,231
^A0N,25,24
^FH\
^FN15^FD${barcodeIDconverted}^FS
^FS
^FT60,196
^A0N,23,24
^FH\
^FN14^FD${selectedColour}^FS
^FS
^FT187,196
^A0N,23,24
^FH\
^FN14^FD${mainCategoryPrint}^FS
^FS
^FT186,101
^A0N,54,52
^FH\
^FN11^FD$${price}^FS
^FS
^XZ` : `^XA
      ^XFE:CCMLB22.ZPL^FS
      ^FN11^FD$${price}^FS
      ^FN12^FD${stockNumber}^FS
      ^FN13^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}^FS
      ^FN14^FD${mainCategoryPrint}^FS
      ^FN15^FD${barcodeIDconverted}^FS
      ^PQ1
      ^XZ`
    }
  }
  return {
    getItemDetails,
    palletInfo,
    selectedTeamNumber,
    upcInfo,
    updateUPCPrice,
    enabledPriceEdit,
    setEnabledPriceEdit,
    removeUPCText,
    setRemoveUPCText,
    getStandardPriceForUPC,
  }
}
function getBarcodeForLetter(
  barcodeIDZPL: string,
  barcodeID: string,
  price: string,
  upc: string | number,
  barcodeDesc: string,
  categoryKeyWords: string,
  stockNumber: string,
  barcodeIDconverted: string,
  newDesc: string,
  selectedSubCategoryForPrint: any,
  selectedColour: string,
  priceId: string
) {
  const barCodeTitle =
    upc === ''
      ? ''
      : `^FH\
^CI28
^FDCOMP EST VALUE
^FS`
  barcodeIDZPL = selectedColour ? `^XA
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^JUS
^LRN
^CI27
^PA0,1,1,0
^MMT
^PW406
^LL243
^LS0
^FT260,96
^A0N,25,25
^FH\
^CI27
^FPH,1
^FT240,135
^A0N,10,10
^FH\
^CI28
^FN1^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}
^CI27
^FT15,150
^BON,4,N,0,N,1,
^FH\
^FN2bCODE
^FS
^CI28
^FO200, 40
^GB110,70,70
^FS
^FT190,100
^A0N,60,60
^FB100,1,0,R
^FR
^FH^FD${categoryKeyWords}^FS
^CI27
^FPH
^FT190,140
^A0N,25,20
${barCodeTitle}
^FPH,1
^FT230,165
^A0N,24,24
^FH\
^CI28
^FN3^FD$${price}
^FS
^FPH,1
^FT260,180
^A0N,17,17
^FH\
^CI28
^FO190,170^GB160,0,3^FS
^FS
^FPH,1
^FT200,195
^A0N,20,20
^FH\
^CI28
^FN4^FD${stockNumber}| ${selectedColour}^FS
^FS
^CI27
^FO360,205
^FS
^FT1,190
^A0N,22,20
^FH\
^CI28
^FN5^FD${barcodeIDconverted}
^FS
^CI27
^FO360,205
^FS
^FT170,230
^A0N,25,25
^FH\
^CI28
^FN5^FD${newDesc}
^FD${selectedSubCategoryForPrint}
^FS
^XZ` : `^XA
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^JUS
^LRN
^CI27
^PA0,1,1,0
^MMT
^PW406
^LL243
^LS0
^FT260,96
^A0N,25,25
^FH\
^CI27
^FPH,1
^FT240,135
^A0N,10,10
^FH\
^CI28
^FN1^FD${barcodeID}$${price}!#&${priceId === 'TEM' ? 'U:' : 'U:' + upc}&D:${barcodeDesc}
^CI27
^FT15,150
^BON,4,N,0,N,1,
^FH\
^FN2bCODE
^FS
^CI28
^FO200, 40
^GB110,70,70
^FS
^FT190,100
^A0N,60,60
^FB100,1,0,R
^FR
^FH^FD${categoryKeyWords}^FS
^CI27
^FPH
^FT190,140
^A0N,25,20
${barCodeTitle}
^FPH,1
^FT230,165
^A0N,24,24
^FH\
^CI28
^FN3^FD$${price}
^FS
^FPH,1
^FT260,180
^A0N,17,17
^FH\
^CI28
^FO190,170^GB160,0,3^FS
^FS
^FPH,1
^FT230,195
^A0N,20,20
^FH\
^CI28
^FN4^FD${stockNumber} 
^FS
^CI27
^FO360,205
^FS
^FT1,190
^A0N,22,20
^FH\
^CI28
^FN5^FD${barcodeIDconverted}
^FS
^CI27
^FO360,205
^FS
^FT170,230
^A0N,25,25
^FH\
^CI28
^FN5^FD${newDesc}
^FD${selectedSubCategoryForPrint}
^FS
^XZ`
  return barcodeIDZPL
}
